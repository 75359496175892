import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@axios'
import config from '../../config'

let BASE_URL = config.BASE_URL;
const API_BASE_SUBSCRIPTIONS = BASE_URL + '/subscriptions'

Vue.use(Vuex)

export default new Vuex.Store({
    namespace: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async fetchSubscriptions() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_BASE_SUBSCRIPTIONS}/fetchSubscriptions`)
                    .then((subscriptions) => {
                        this.commit('getSubscriptions', subscriptions);
                        resolve(subscriptions)
                    })
                    .catch(error => reject(error))
            })
        },
    },
    strict: process.env.DEV,
})
